import { RefObject, createRef, useState } from "react";
import { Dispatch, SetStateAction, CSSProperties } from "react";


type hexColor = `#${string}`;
export type themeColors = {
    iconic: hexColor,
    back: hexColor,
    fore: hexColor,
    falt: hexColor,
    bar: hexColor,
};
const defaultOpacity = 0.5;


function loadTheme(idx:number) {
    let loadedTheme:themeColors;
    switch(idx) { 
        case 0: {
            loadedTheme = {
                iconic: "#66cc00",
                back: "#fcfafa",
                fore: "#6e8387",
                falt: "#a4b8c4",
                bar: "#c8d3d5",
            };
            break; 
        } 
        case 1: { 
            loadedTheme = {
                iconic: "#66cc00",
                back: "#000000",
                fore: "#a8aec1",
                falt: "#a09ebb",
                bar: "#33202a",
            };
            break; 
        } 
        default: { 
            throw new RangeError(
                `loadTheme() expects numbers from 0 to 1, but got ${idx}.`
            );
        }
    } 
    return loadedTheme;
}


function loadLang(idx:number) {
    let loadedLang:string;
    switch(idx) { 
        case 0: {
            loadedLang = 'EN';
            break; 
        } 
        case 1: { 
            loadedLang = 'TH';
            break; 
        } 
        case 2: {
            loadedLang = 'JP';
            break;
        }
        default: { 
            throw new RangeError(
                `loadTheme() expects numbers from 0 to 2, but got ${idx}.`
            );
        }
    } 
    return loadedLang;
}


function LocalButton(props:{
    id:string,
    label:string,
    style:CSSProperties,
    onclick:() => void,
    active?:boolean,
}) {
    const isActive = props.active!==undefined? props.active:false
    const [currentActive, setCurrentActive] = useState<boolean>(isActive);
    const [currentOpacity, setCurrentOpacity] = useState<number>(defaultOpacity);
    return (
        <div 
            className="localButton" 
            id={props.id} 
            style={
                {
                    ...props.style, 
                    "opacity": isActive? 1:currentOpacity
                }
            } 
            onMouseOver={
                () => {
                    setCurrentOpacity(1);
                }
            }
            onMouseLeave={
                () => {
                    setCurrentOpacity(isActive? 1:defaultOpacity);
                }
            }
            onClick={
                () => {
                    props.onclick();
                    setCurrentActive(!currentActive);
                }
            }
        >
            {props.label}
        </div>
    )
}


function LinkButton() {
    return (
        <div />
    )
}


function LinkImage(props:{
   id:string,
   srcs:string[],
   link:string,
   srcsig:number, 
}) {
    const idx = props.srcsig<props.srcs.length? props.srcsig:0;
    return (
        <div className="linkImage" id={props.id}>
            <a href={props.link} target='_blank' rel='noreferrer'>
                <img 
                    src={props.srcs[idx]} 
                    alt="unavailable" 
                />
            </a>
        </div>
    )
}


function LoadedText() {
    return (
        <div />
    )
}


function DropdownList() {
    return (
        <div />
    )
}


function RadioPanel(props:{
    id:string,
    choices:string[],
    bcolor:string,
    tcolor:string,
    setfunc:Dispatch<SetStateAction<number>>,
    chsig:number,
}) {
    const [currentSelection, setCurrentSelection] = useState<number>(props.chsig);
    return (
        <div 
            className="radioPanel" 
            id={props.id} 
            style={
                {
                    "border": "2px solid "+props.bcolor,
                }
            }
        >
        {
            props.choices.map(
                (ch:string, i:number) => {
                    return <LocalButton 
                        key={props.id+String(i)}
                        id={props.id+String(i)}
                        label={ch} 
                        onclick={
                            () => {
                                props.setfunc(i);
                                setCurrentSelection(i);
                            }
                        }
                        style={
                            {
                                "backgroundColor": props.bcolor,
                                "color": props.tcolor,
                            }
                        }
                        active={i===currentSelection}
                    />;
                }
            )
        }
      </div>
    );
  }


export {
    loadTheme, 
    loadLang,
    LocalButton, 
    LinkButton, 
    LinkImage, 
    LoadedText, 
    DropdownList,
    RadioPanel,
}