import { useState, useEffect, useRef } from 'react';

import './main.css';
import { themeColors, loadTheme, loadLang } from './global';
import { LinkButton, LinkImage, LoadedText, DropdownList, RadioPanel } from './global'


export default function App() {
  const isMounted = useRef(false);
  const [currentTheme, setCurrentTheme] = useState<number>(0);
  const [currentLang, setCurrentLang] = useState<number>(0);
  let activeTheme:themeColors = loadTheme(currentTheme);
  let activeLang:string = loadLang(currentLang);
  useEffect(
    () => {
      if(!isMounted.current) {
        isMounted.current = true;
        return;
      }
      activeTheme = loadTheme(currentTheme);
      activeLang = loadLang(currentLang);
    }
  );

  return (
    <div className="layout" id="mainapp">  
      <div className="layout" id="mainbar" style={{"backgroundColor": activeTheme.bar}}>
        <div className="layout" id="header">
          <DropdownList />
          <LinkButton />
        </div>
        <div className="layout" id="settings">
          <RadioPanel 
            id="lang" 
            choices={["ENG", "ไทย", "日本"]} 
            bcolor={activeTheme.back} 
            tcolor={activeTheme.fore} 
            setfunc={setCurrentLang}
            chsig={currentTheme}
          />
          <RadioPanel 
            id="theme" 
            choices={["A", "B"]} 
            bcolor={activeTheme.falt} 
            tcolor={activeTheme.falt} 
            setfunc={setCurrentTheme}
            chsig={currentLang}
          />
        </div>
        <div className="layout" id="platforms">
          <LinkImage 
            id="scholar" 
            srcs={["google_scholar_icon.png"]} 
            link="https://scholar.google.com/citations?user=h0xAk1gAAAAJ&hl=en&oi=ao"
            srcsig={currentTheme} 
          />
          <LinkImage 
            id="linkedin" 
            srcs={["linkedin_icon.png"]} 
            link="https://www.linkedin.com/in/thanapong-sommart" 
            srcsig={currentTheme}
          />
          <LinkImage 
            id="github" 
            srcs={["github_icon_light.png", "github_icon_dark.png"]} 
            link="https://github.com/KindaOP" 
            srcsig={currentTheme}
          />
        </div>
      </div>
      <div className="layout" id="profile">
        <div className="element" id="backdrop" style={{"opacity": currentTheme===0? 0.8:1}} />
        <img id="photo" />
        <div className="layout" id="profile">
          <LoadedText />
          <LoadedText />
          <LoadedText />
        </div>
      </div>
      <Calendar theme={activeTheme} lang={activeLang} />
    </div>
  );
}


function Calendar(props:{theme:themeColors, lang:string}) {
  return (
    <div />
  );
}